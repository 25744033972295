export default [
  {
    path: "/app/servers",
    component: () => import("../views"),
    meta: {
      title: "Educasis - Servidores",
      requiresAuth: true,
    },
    children: [
      //----------------------------Servers----------------------------
      {
        path: "function-servers",
        name: "servers.function-servers",
        component: () => import("../function-servers/views/List"),
      },
      {
        path: "function-servers/create",
        name: "servers.function-servers.create",
        component: () => import("../function-servers/views/Create"),
      },
      {
        path: "function-servers/show/:id",
        name: "servers.function-servers.show",
        component: () => import("../function-servers/views/Show"),
      },
      {
        path: "function-servers/edit/:id",
        name: "servers.function-servers.edit",
        component: () => import("../function-servers/views/Edit"),
      },
    ],
  },
];
